<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'

    import * as sdkclaro from "@claro/sdkclaro";
    export default {
        components: { MyButton },
        setup () {
            return { v$: useVuelidate() }
        },
        data: () => ({
            userEmail: '',
        }),
        validations: () => ({
            userEmail: { required, email },
        }),
        mounted(){
            this.validateProfileClaroPay()
        },
        methods: {
            validateProfileClaroPay(){
                
                sdkclaro.getInstance().getTopic(
                    "getProfile",
                    "",
                (result)=>{
                    console.log(result)
                    try {
                        const res = result;
                        if (res != '') {
                            this.$store.state.userId = res.username;
                            this.$store.dispatch('checkMembership')
                            this.$store.dispatch('checkProfile')
                            localStorage.setItem('claro-pay-id', res.username)
                            this.$store.commit('setClaroPayId');
                            this.saveProfileClaroPay(result)
                        }
                    } catch (error) { console.log(error); }
                    
                },
                (error)=>{
                    console.log(error)
                })
            },
            saveProfileClaroPay(data){
                let params = {
                    curp : data.CURP,
                    ine : data.INE,
                    rfc : data.RFC,
                    alias : data.alias,
                    respuesta : data.answer,
                    fecha_nacimiento : data.birthDate,
                    lugar_nacimiento : data.birthPlace,
                    lugar_nacimiento_id : data.birthPlaceId,
                    ciudad : data.city,
                    ciudad_id : data.cityId,
                    pais : data.country,
                    created_at : data.creationDate,
                    dispositivo : data.device,
                    email : data.email,
                    numero_exterior : data.externalNumber,
                    nombre_padre_usuario : data.fathersName,
                    sexo_id : data.genderKey,
                    sexo : data.gendervalue,
                    //data.instabug,
                    numero_interior : data.internalNumber,
                    //data.isTelcel,
                    dok_id : data.keyDokId,
                    nombre_madre_usuario : data.mothersName,
                    numero_telefonico : data.msisdn,
                    nombre : data.name,
                    vecindario : data.neighborhood,
                    vecindarioId : data.neighborhoodId,
                    //opmActivation,
                    cp : data.postalCode,
                    imagen_perfil : data.profileImage,
                    pregunta : data.question,
                    segundo_nombre : data.secondName,
                    estado : data.state,
                    estado_id : data.stateId,
                    calle : data.street,
                    terminos : data.terms,
                    //updateFromOPM,
                    claro_pay_id : data.username,
                }
                axios.post(process.env.VUE_APP_SERVICE_URL + `saveProfile`, params).then(res => {
                    this.$router.replace({ path: '/inicio' })
                }).catch(error => { console.log(error) })
            },
            async handleSubmit(){
                const result = await this.v$.$validate()
                if (!result) return
            }

            /*async handleSubmit(){
                const result = await this.v$.$validate()
                if (!result) return

                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + `perfiles?correo_electronico=${this.userEmail}`);

                    if (res.data != '') {
                        this.$store.state.userId = res.data.claro_pay_id;
                        this.$store.dispatch('checkMembership')
                        this.$store.dispatch('checkProfile')
                        localStorage.setItem('claro-pay-id', res.data.claro_pay_id)
                        this.$store.commit('setClaroPayId');
                        this.$router.replace({ path: '/inicio' }) 
                        
                    }
                } catch (error) { console.log(error); }
            }*/
        }
    }
</script>
<template>
    <div class="login-view app-view pt-35">
        <!--<div class="container">
            <h1 class="tx-24 tx-bold tx-center mb-0">
                Login
            </h1>
            <img class="decore-shine mb-13" src="@/assets/images/layout/shine-1.png" alt="">

            <p class="tx-18 tx-center mb-20">
                simulate login
            </p>

            EMAIL
            
            <div class="form-group mb-7">
                <input class="form-input" type="text" placeholder="Email" maxlength="50" v-model.trim="userEmail">
            </div>
            <div class="tx-14 opacity-08 tx-center mb-4 -mt-5" v-if="v$.userEmail.$error">
                Favor de escribir un correo electrónico valido
            </div>

            <my-button label="Iniciar" path="/inicio/arena-zuggy" @clicked="handleSubmit()"/>
        </div>-->
    </div>
</template>