<script>
    import axios from 'axios'

    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import ShoppingHistoryItem from '@/components/ShoppingHistoryItem.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { ControlsBackAndHome, ShoppingHistoryItem, HeaderComponent },
        data: () => ({
            historyProducts: [],
            userProfile: {},
        }),
        methods: {
            async getProfile() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + `perfilamiento?usuario_id=${this.$store.getters['userId']}`)
                    this.userProfile = res.data[0];

                    var hoy = new Date();
                    var cumpleanos = new Date(res.data[0].fecha_de_nacimiento);
                    this.edad = hoy.getFullYear() - cumpleanos.getFullYear();

                } catch (err) { console.log(err) }
            },
            async getShoppingHistory(){
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + `historial-compras?usuario_id=${this.$store.getters['userId']}`)
                    this.historyProducts = res.data.sort((a, b) => {
                        return new Date(a.fecha_compra) - new Date(b.fecha_compra)
                    }).reverse();
                    console.log(res);
                } catch (err) {
                    console.log(err);
                }
            } 
        },
        mounted() {
            sdkclaro.getInstance().switchGoBackButton(true);
            this.getShoppingHistory();
            this.getProfile();
            this.trackLog({ 
                action: 'Ingreso a historial de compras', 
                medio: 'Botón', 
                sectionId: 7, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="shopping-history-view app-view pt-36">
        <header-component>
            <h1 class="tx-24 tx-center mb-0">
                Historial <br> de compras
            </h1>
            <p class="tx-center mb-0">
                {{ userProfile.puntos }} puntos
            </p>
        </header-component>

        <div class="container">
<!--             <div class="flex align-center mb-4"> -->
                <!-- INPUT -->
<!--                 <div class="form-input relative input-group rounded-10 mr-4">
                    <span class="input-group-helper bg-grey-1 border-1 border-grey-3 cursor-pointer">
                        <img class="w-5" src="@/assets/images/views/create_profile/icon-glass.svg" alt="">
                    </span>
                    <input class="input-group-field pl-0" type="text" placeholder="Buscar">

                    <img class="decore-shine absolute l-0 r-0 b-0 -mb-2px" src="@/assets/images/layout/shine-btn.png" alt="">
                </div> -->

                <!-- FILTERS -->
<!--                 <div class="h-10 w-10 grid place-items-center">
                    <img class="w-6" src="@/assets/images/views/create_profile/icon-filters.svg" alt="">
                </div>
            </div> -->

            <!-- LIST -->
            <div class="tx-14 tx-center opacity-07 mt-15" v-if="historyProducts.length <= 0">
                Aún no has realizado tu primera compra
            </div>
            <div v-else>
                <shopping-history-item class="mb-4" v-for="(item, idx) in historyProducts" :key="idx" :data="item"/>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .form-input {
        background-color: rgba($color: #fff, $alpha: 0.35);
        border: none;
    }

    .input-group-helper,
    .input-group-field{
        border: none;
    }

    .input-group-field{
        background-color: rgba($color: #fff, $alpha: 0);
        color: #fff;

        &::placeholder{ color: #B4C5F2; }
    }
</style>