<script>
    import axios from 'axios';

    import HeaderComponent from '@/components/layout/HeaderComponent.vue';
    import NavBarMenu from '@/components/layout/NavBarMenu.vue';
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { HeaderComponent, NavBarMenu },
        data: () => ({
            current: {},
            loaded: false,
            loading: true,
            iframe: {
                src: 'https://www.viveplus.com.mx/beneficios?c=Zuggy&i=63',
                style: null,
                wrapperStyle: null,
            }
        }),     
        methods: {
            async getApiVive (){
                try{
                    const res = await axios.post('https://api.viveplus.com.mx/v1/?key=pQZ6i6WFeVeco9jP2tLe5F6Ad&m=lista_marcas');
                    console.log(res.data.Marcas)
                    this.current = res.data.Marcas
                    this.load()
                } catch (err) { console.log(err) }
            },
            async getReward (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'detalle-beneficios-zuggy?id_beneficio=' + this.$route.params.id);
                    this.current = res.data
                } catch (err) { console.log(err) }
            },
            async goToDownloadCupon(id){
                try{
                    const res = await axios.post('https://api.viveplus.com.mx/v1//?m=cupon_viveplus&key=pQZ6i6WFeVeco9jP2tLe5F6Ad&id_marca=' + id);
                    this.downloadCupon(res)
                } catch (err) { console.log(err) }
            },
            downloadCupon(res){
                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: res.data.url_cupon,
                });
            },
            load (){
                this.loading = false
            },
        },
        beforeMount() {
            this.getApiVive()
            sdkclaro.getInstance().switchGoBackButton(true)
            /*this.trackLog({ 
                action: 'Ingreso a iframe', 
                medio: 'Banner', 
                sectionId: 9, 
                elementId: this.$route.params.id, 
            })

            this.iframe.style = {
                position: 'absolute',
                width: window.innerWidth,
                height: window.innerHeight,
                top: "0px",
                left: "0px",
                height: "100%",
                width: "100%"

            }
            this.loaded = true;*/
        }
    }
</script>

<template>
    <div class="reward-detail-view app-view pt-28">
        <header-component burgerBtn>
            <h2 class="tx-24 tx-bold tx-center mb-0">
                Recompensas
            </h2>
        </header-component>
        <!--<div class="container">
            <iframe 
                v-if="loaded"
                :src="iframe.src"
                :style="iframe.style"
                :height="iframe.style.height"
                :width="iframe.style.width"
                frameborder="0"
                @load="load"
            ></iframe>
        </div>-->
        <div v-for="(current, idx) in this.current" :key="idx" class="p-5">
            <div style="cursor: pointer;" class="" @click="goToDownloadCupon(current.id_marca)">
                <div class="relative bg-center bg-cover bg-no-repeat h-55 w-100pr rounded-10 border-1 border-white mb-5" :style="{ 'background-image': 'url(' + current.imagen_marca + ')' }">
                    <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
                </div>
                <!---->
                <p class="tx-20 tx-bold" v-html="current.nombre_marca"></p>
                <p class="tx-14 tx-bold" v-html="current.promo_desc_marca"></p>
                <!-- BODY -->
                <div class="tx-14 tx-medium mb-6" v-html="current.restricciones_marca"></div>
            </div>
        </div>
        <!-- LOADER -->
        <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
            <div class="loader-spinner"></div>
        </div>
        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>

<style lang="scss" scoped>
    .overlay{
        background: rgba($color: #000, $alpha: 0.4)
    }
</style> 